import styled from "styled-components";

const Wrapper = styled.div`
  text-align: left;
  .form-items {
    font-size: 15px;
    margin-top: 5%;
  }
  .input {
    border: 1px solid #808080;
  }

  .field {
    span {
      color: red;
    }
    margin-bottom: 3%;
  }
`;

export const ErrorLabel = styled.div`
  color: #ff3938;
  font-size: 13px;
`;
export default Wrapper;
