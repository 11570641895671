
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React, { useState } from 'react'
import Head from 'next/head'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import {
  MainContainer,
  LoginContainer,
  LeftSection,
  FormWrapper,
  FormHeader,
  RightSection,
} from './Login.style'
import LoginFrom from '../../components/authentication/Login/LoginFrom'
import { langConverterReversed, localeToLang } from '../../helpers/urlhelpers'
import Logo from '../../components/UI/Layout/Logo'

function Login() {
  const router = useRouter()
  const { pathname, asPath, query, locale } = useRouter()
  const [selectedCountry, setSelectedCountry] = useState(localeToLang(locale))

  const { t, lang } = useTranslation()

  return (
    <MainContainer>
      <Head>
        <title>Login </title>
      </Head>
      <LoginContainer>
        <LeftSection>
          {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ReactFlagsSelect
              className="flagsSelect"
              countries={['US', 'TR']}
              customLabels={{
                US: 'English',
                TR: 'Türkçe',
              }}
              showSelectedLabel={false}
              fullWidth={false}
              selectedSize={15}
              selected={selectedCountry}
              onSelect={(code) => {
                setSelectedCountry(code)
                router.push({ pathname, query }, asPath, {
                  locale: langConverterReversed(code),
                })
              }}
            />
          </div> */}
          <FormWrapper>
            <FormHeader>
              <Logo />
              <h1>
                {t('auth:createAccount')} <br />
              </h1>
              <p>
                {t('auth:slogan')} <br />
              </p>
            </FormHeader>
            <LoginFrom t={t} lang={lang} />
          </FormWrapper>
        </LeftSection>
        <RightSection />
      </LoginContainer>
    </MainContainer>
  )
}

export default Login


    export async function getStaticProps(ctx) {
        
        
        return {
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/login/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  