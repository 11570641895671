import * as Yup from "yup";

export const LoginValidation = (t) =>
  Yup.object().shape({
    username: Yup.string()
      .email(t("auth:emailControl"))
      .required(t("auth:required")),
    password: Yup.string()
      .min(6, t("auth:passwordmin6"))
      .required(t("auth:required")),
  });
