import React from "react";
import { Input, Button, Divider, Alert } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Link from "next/link";
import { LoginValidation } from "./LoginValidation";
import Wrapper from "./LoginForm.style";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/Actions/AuthenticationAction";
import LoginGoogle from "../Register/LoginGoogle";

function LoginFrom({ t, lang }) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.Authentication.loading);
  const errMsg = useSelector((state) => state.Authentication.errMsg);
  const failed = useSelector((state) => state.Authentication.failed);
  const router = useRouter();

  return (
    <Wrapper>
      {failed && <Alert description={t("auth:" + errMsg)} type="error" />}
      <br />
      <Formik
        validateOnBlur={false}
        validationSchema={LoginValidation(t)}
        onSubmit={async (values) =>
          await dispatch(login(values, router.query.returnUrl))
        }
        initialValues={{ username: "", password: "" }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <LoginGoogle loading={loading} title={t("auth:loginGoogle")} />
            <Divider plain>{t("auth:or")}</Divider>
            <div className={"field"}>
              <label className="form-items">{t("auth:email")}</label>
              <Field name="username" type="text">
                {({ field, form: { handleBlur } }) => {
                  return (
                    <Input
                      className="input"
                      style={
                        touched.username &&
                        errors.username && { borderColor: "#ff3938" }
                      }
                      size="large"
                      {...field}
                      type="text"
                      onBlur={(e) => {
                        const newValue = e.target.value.toLowerCase().trim();
                        setFieldValue(field.name, newValue);
                        handleBlur(e);
                      }}
                    />
                  );
                }}
              </Field>
              <ErrorMessage component={"span"} name={"username"} />
            </div>
            <div className={"field"}>
              <label className="form-items">{t("auth:password")}</label>
              <Field name="password" type="text">
                {({ field, form }) => (
                  <Input.Password
                    className="input"
                    style={
                      touched.password &&
                      errors.password && { borderColor: "#ff3938" }
                    }
                    size="large"
                    {...field}
                    type="text"
                  />
                )}
              </Field>
              <ErrorMessage component={"span"} name={"password"} />
            </div>
            <a onClick={() => router.push("/forgot-password")}>
              {t("auth:forgotPassword")}
            </a>
            <Button
              loading={loading}
              className="form-items"
              size="large"
              type="primary"
              htmlType="submit"
              block
            >
              {t("auth:loginSubmit")}
            </Button>
            <span
              style={{
                width: "100%",
                marginTop: "10%",
                display: "inline-block",
                textAlign: "center",
              }}
            >
              {t("auth:dontHaveAccount")}
            </span>
            <br />
            <Link
              href={{
                pathname: "/register",
                query: router.query.returnUrl
                  ? { returnUrl: router.query.returnUrl }
                  : {},
              }}
            >
              <a
                style={{
                  width: "100%",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                {t("auth:freeAccount")}
              </a>
            </Link>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
}

export default LoginFrom;
