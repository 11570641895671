
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .login-card {
    width: 450px;
    -webkit-box-shadow: 2px 2px 15px 5px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 2px 15px 5px rgba(0, 0, 0, 0.47);
  }
`

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  h1 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.25em;
    margin-bottom: 0.25rem;
  }
  .flagsSelect {
    margin-top: 18px;
  }

  .flagsSelect * {
    line-height: 18px !important;
  }

  .flagsSelect button {
    border: none;
    padding: 5px 5px;
    width: 60px;
  }

  .flagsSelect li {
    padding: 4px 20px 4px 10px;
  }

  .flagsSelect ul {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
  }
`

export const LoginContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  height: 100%;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 24px 40px;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  max-width: 20rem;
  text-align: center;
  margin: 0px auto;
  -webkit-box-flex: 1;
  flex-grow: 1;
`

export const FormHeader = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
`

export const RightSection = styled.div`
  background: url('/images/devices.png') center / 90% no-repeat rgb(255, 255, 255);
  @media (max-width: 1024px) {
    display: none;
  }
`

export default Wrapper


    export async function getStaticProps(ctx) {
        
        
        return {
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/login/Login.style',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  